import React, { Component } from "react";
import { T, useT } from "@transifex/react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { SpeechService } from "./components/services";
import ActionPanel from "./components/ActionPanel";
import { VenueService } from "../../../../services/api/Venue";
import { LoadingScreen } from "../../../../components/Loading";
import { ActionService } from "../../../../services/api/Action";
import { DialogError } from "../../../../themes/default/Dialog";
import { ResponseService } from "../../../../services/utils/Response";
import { ActionFilterService } from "../../../../services/api/ActionFilter";
import { ColorService } from "../../../../services/api/Color";
import Dialog from "../../../../themes/default/Dialog/Dialog";
import { Input } from "../../../../themes/default/Form/components/Input";
import {
  Button,
  ButtonAdd,
} from "../../../../themes/default/Form/components/Button";
import { SceneTitle } from "../../../../themes/default/Title/components/Scene";
import { Panel } from "../../../../themes/default/Layout/components/Panel";
import {
  ValidatorService,
  NotBlank,
  MinLength,
  MaxLength,
  Type,
} from "pretty-validator";

import ActionDataSourcesProvider from "./components/ActionDataSourcesProvider";
import "./index.css";

/**
 * @class ./scenes/Actions/Actions
 */
class Actions extends Component {
  /**
   * @type {ActionService}
   */
  actionService;

  /**
   * @type {ActionFilterService}
   */
  actionFilterService;

  /**
   * @type {ValidatorService}
   */
  validatorService;

  /**
   * @type {String}
   */
  venueId;

  /**
   * @type {VenueService}
   */
  venueService;

  /**
   * @type {ResponseService}
   */
  responseService;

  /**
   * @type {SpeechService}
   */
  speechService;

  state = {
    showDeleteConfirmationDialog: false,
    numberOfPanels: 1,
    actionNameField: "",
    oldActionFilterName: "",
    showAddActionModal: false,
    showEditActionModal: false,
    newActionErrors: "",
    newActionName: "",
    actionId: "",
    panelId: "",
    actionPanels: {},
    actionList: {},
    showLoadingScreen: true,
    showErrorDialog: false,
    errorMessages: [],
    filterConfigKeys: null,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.venueId = this.props.match.params.id;
    this.validatorService = new ValidatorService();
    this.actionService = new ActionService();
    this.actionFilterService = new ActionFilterService();
    this.responseService = new ResponseService();
    this.venueService = new VenueService();
    this.speechService = new SpeechService();
  }

  /**
   * @override
   */
  async componentDidMount() {
    let keys = {};
    this.venueService
      .getAllTicketPropertyKeys(this.venueId)
      .then((response) => {
        if (response.status === 200) {
          response.data.map((key) => {
            keys[key] = key;
            return null;
          });
          this.setState({ filterConfigKeys: keys });
        } else {
          if (
            JSON.stringify(this.state.errorMessages) !==
            JSON.stringify(this.responseService.getErrorMessages(response.data))
          ) {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
        }
      });

    try {
      const colors = await ColorService.getColors();
      this.setState({ colors });
    } catch (error) {
      console.error("Error fetching colors:", error);
      this.setState({ errorMessages: ["Failed to load colors"], showErrorDialog: true });
    };

    this.getActionList();

    this.getActionPanels();
  }

  /**
   * Returns actions list for action select field.
   */
  getActionList = () => {
    this.actionService.getAll().then((response) => {
      let actionList = {};
      response.map((action) => {
        actionList[action.id] = action.name;
      });

      this.setState({ actionList: actionList });
    });
  };

  /**
   * Gets data from api and sets to state.
   */
  getActionPanels = () => {
    !this.state.showLoadingScreen &&
      this.setState({
        showLoadingScreen: true,
      });
    this.actionFilterService.getAll(this.venueId).then((response) => {
      if (response.status === 200) {
        this.setState({
          actionPanels: response.data,
          showLoadingScreen: false,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveValue = (value) => {
    let nameFieldErrors = this.state.newActionErrors;

    this.setState({
      newActionName: value,
      newActionErrors: this.isValidActionName(value) ? [] : nameFieldErrors,
    });
  };

  /**
   * Checks if venue name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidActionName = (name = this.state.newActionName) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        newActionErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Handles adding new action panel.
   */
  onAddPanel = () => {
    if (this.isValidActionName()) {
      const { actionList, newActionName, colors } = this.state;

      let actionFilter = {
        actionId: Object.keys(actionList)[0],
        name: newActionName,
        colorId: colors[0].id
      };

      this.setState({
        showLoadingScreen: true,
      });
      this.actionFilterService
        .createOne(this.venueId, actionFilter)
        .then((response) => {
          if (response.status === 201) {
            this.getActionPanels();
            this.closeAddNewActionFilterModal();
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
          this.setState({
            showLoadingScreen: false,
          });
        });
    }
  };

  /**
   * Closes addNewActionModal and empty state.
   */
  closeAddNewActionFilterModal = () => {
    this.setState({
      showAddActionModal: false,
      newActionErrors: "",
      newActionName: "",
    });
  };

  /**
   * Closes editActionModal and empty state.
   */
  closeEditActionFilterModal = () => {
    this.setState({
      showEditActionModal: false,
      newActionErrors: "",
      newActionName: "",
      oldActionFilterName: "",
    });
  };

  /**
   * Reorganize panels objects and save in new order.
   *
   * @param oldIndex
   * @param newIndex
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    let panelsArray = Object.entries(this.state.actionPanels);
    let reorderedPanels = arrayMove(panelsArray, oldIndex, newIndex);
    let oldActionFilter = reorderedPanels[newIndex][1];
    let name = oldActionFilter.name;

    let actionFilter = {
      ...oldActionFilter,
      priority: reorderedPanels.length - newIndex,
      actionId: oldActionFilter.actionId,
      name,
      confirmationRequired: oldActionFilter.confirmationRequired,
      onlyOnce: oldActionFilter.onlyOnce,
      rules: oldActionFilter.rules,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.actionFilterService
      .updateOne(this.venueId, name, actionFilter)
      .then((response) => {
        if (response.status === 204) {
          this.getActionPanels();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  handleActionFilterChanged = (actionFilter, panelId) => {
    this.setState({
      showLoadingScreen: true,
    });
    actionFilter.priority =
      this.state.actionPanels.length - (parseInt(panelId) + 1);
    this.setState({
      actionPanels: this.state.actionPanels.map((af, index) =>
        index === parseInt(panelId) ? actionFilter : af
      ),
    });
    this.actionFilterService
      .updateOne(this.venueId, actionFilter.name, actionFilter)
      .then((response) => {
        if (response.status !== 204) {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
          this.getActionPanels();
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Edits action panel.
   */
  editActionPanelHandler = () => {
    const { newActionName, panelId, actionPanels } = this.state;

    let oldActionFilter = actionPanels[panelId];
    let priority = actionPanels.length - (parseInt(panelId, 10) + 1);

    let actionFilter = {
      ...oldActionFilter,
      priority: priority,
      actionId: oldActionFilter.actionId,
      name: newActionName,
      confirmationRequired: oldActionFilter.confirmationRequired,
      rules: oldActionFilter.rules,
      onlyOnce: oldActionFilter.onlyOnce,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.actionFilterService
      .updateOne(this.venueId, oldActionFilter.name, actionFilter)
      .then((response) => {
        if (response.status === 204) {
          this.getActionPanels();
          this.closeEditActionFilterModal();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Removes action panel.
   *
   * @param {Number} panelId
   */
  removeAction = (panelId) => {
    let name = this.state.actionPanels[panelId].name;

    this.setState({
      showLoadingScreen: true,
    });
    this.actionFilterService.deleteOne(this.venueId, name).then((response) => {
      if (response.status === 204) {
        this.getActionPanels();
        this.setState({
          showDeleteConfirmationDialog: false,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Generates object from tag value string and prepare for update API.
   *
   * @param {String} tagValueString
   *
   * @returns {Array}
   */
  generateObjectFromString = (tagValueString) => {
    if (!tagValueString) {
      return [];
    }

    let orParts = tagValueString.split(" || ");

    return orParts.map((orPart) => {
      let andParts = orPart.split(" && ");

      return andParts.map((andPart) => {
        let operators = ["=", "!=", "<", ">", "<=", ">=", "@=", "!@="];
        let splitOperator = "";
        operators.map((operator) => {
          if (andPart.search(operator) >= 0) {
            splitOperator = operator;
          }

          return true;
        });
        if (splitOperator !== "") {
          let operator = "";
          switch (splitOperator) {
            case "=":
              operator = "equal";
              break;
            case "!=":
              operator = "notEqual";
              break;
            case "<":
              operator = "less";
              break;
            case ">":
              operator = "greater";
              break;
            case "<=":
              operator = "lessEqual";
              break;
            case ">=":
              operator = "greaterEqual";
              break;
            case "@=":
              operator = "contains";
              break;
            case "!@=":
              operator = "notContains";
              break;
            default:
              operator = "=";
          }
          let tagData = andPart.split(" " + splitOperator + " ");

          tagData = tagData.filter(function (e) {
            return e.trim() !== "";
          });

          return {
            operator: operator,
            field: tagData[0] ? tagData[0].trim() : "",
            operand: tagData[1] ? tagData[1] : "",
          };
        }

        return true;
      });
    });
  };

  /**
   * Gets data from a Tag component and set to new state.
   *
   * @param {Object} tagValue
   */
  getTagValue = (tagValue) => {
    let value = tagValue.value;
    let panelId = tagValue.uid;
    if (panelId) {
      let panels = this.state.actionPanels;
      let oldActionFilter = panels[panelId];
      let oldRules = oldActionFilter.rules;
      let newRules = this.generateObjectFromString(value);
      let priority =
        this.state.actionPanels.length - (parseInt(panelId, 10) + 1);

      if (JSON.stringify(oldRules) !== JSON.stringify(newRules)) {
        panels[panelId] = {
          ...oldActionFilter,
          priority: priority,
          actionId: oldActionFilter.actionId,
          name: oldActionFilter.name,
          confirmationRequired: oldActionFilter.confirmationRequired,
          onlyOnce: oldActionFilter.onlyOnce,
          rules: newRules,
        };

        this.setState({
          actionPanels: panels,
        });

        let actionFilter = panels[panelId];
        this.setState({
          showLoadingScreen: true,
        });
        this.actionFilterService
          .updateOne(this.venueId, actionFilter.name, actionFilter)
          .then((response) => {
            if (response.status === 204) {
              this.getActionPanels();
            } else {
              this.setState({
                showErrorDialog: true,
                errorMessages: this.responseService.getErrorMessages(
                  response.data
                ),
              });
            }
            this.setState({
              showLoadingScreen: false,
            });
          });
      }
    }
  };

  /**
   * Updates state and opens confirmation dialog.
   *
   * @param {String} panelId
   */
  openDeleteConfirmationDialog = (panelId) => {
    this.setState({
      showDeleteConfirmationDialog: true,
      panelId: panelId,
    });
  };

  /**
   * Updates state and opens edit dialog.
   *
   * @param {String} panelId
   */
  openEditActionDialog = (panelId) => {
    let name = this.state.actionPanels[panelId].name;

    this.setState({
      showEditActionModal: true,
      panelId: panelId,
      oldActionFilterName: name,
    });
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      showDeleteConfirmationDialog,
      oldActionFilterName,
      showAddActionModal,
      showEditActionModal,
      newActionErrors,
      panelId,
      actionPanels,
      actionList,
      showLoadingScreen,
      showErrorDialog,
      errorMessages,
    } = this.state;

    const SortableItem = SortableElement(({ value }) => (
      <ActionPanel
        order={value[0]}
        panelId={value[0]}
        venueId={this.venueId}
        actionFilter={value[1]}
        onActionFilterChanged={this.handleActionFilterChanged}
        title={value[1].name}
        actionList={actionList}
        tagValue={value[1].rules}
        action={value[1].actionId}
        keys={this.state.filterConfigKeys}
        getTagValue={this.getTagValue}
        editHandler={this.openEditActionDialog}
        removeHandler={this.openDeleteConfirmationDialog}
        errorHandler={(response) =>
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          })
        }
      />
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div>
        {actionPanels &&
          Object.entries(actionPanels).map((panel, key) => {
            return (
              <SortableItem key={`item-${key}`} index={key} value={panel} />
            );
          })}
      </div>
    ));

    return (
      <div className="ActionFilters">
        <Title name={this.props.venue?.name} />

        <Panel>
          <h4>
            <T _str="Define actions for special tickets" />
          </h4>
          <T _str="e.g. You know that the president of your country comes and you want to welcome him in a special way" />
          .
          <div>
            <Button
              className={ButtonAdd}
              onClickHandler={() => this.setState({ showAddActionModal: true })}
            >
              <T _str="Create new action" />
            </Button>
          </div>
        </Panel>
        <ActionDataSourcesProvider>
          <SortableList
            lockAxis="y"
            distance={5}
            lockOffset={20}
            useDragHandle={true}
            lockToContainerEdges={true}
            items={actionPanels}
            onSortEnd={this.onSortEnd}
          />
        </ActionDataSourcesProvider>
        {/* render Delete Confirmation Dialog */}
        {showDeleteConfirmationDialog && (
          <Dialog
            mainButton={<T _str="Confirm" />}
            title={<T _str="Confirmation dialog" />}
            showModal={showDeleteConfirmationDialog}
            action={() => this.removeAction(panelId)}
            closeModal={() =>
              this.setState({ showDeleteConfirmationDialog: false })
            }
          >
            <h4 className="text-center">
              <T _str="Are you sure you want to delete this action filter?" />
            </h4>
          </Dialog>
        )}

        {/* Shows New Action dialog. */}
        {showAddActionModal && (
          <Dialog
            showModal={showAddActionModal}
            action={() => this.onAddPanel()}
            title={<T _str="Create new action" />}
            closeModal={this.closeAddNewActionFilterModal}
          >
            <Input
              name="Action name"
              getValue={this.receiveValue}
              label={<T _str="Name of action" />}
              className={newActionErrors.length > 0 ? "error" : ""}
              focus
            />
            {newActionErrors.length > 0 &&
              newActionErrors.map((error, key) => (
                <div key={key} className="error">
                  - {error}
                </div>
              ))}
          </Dialog>
        )}

        {/* Shows Edit Action dialog. */}
        {showEditActionModal && (
          <Dialog
            mainButton={<T _str="Update" />}
            showModal={showEditActionModal}
            title={<T _str="Edit action filter name" />}
            action={() => this.editActionPanelHandler()}
            closeModal={this.closeEditActionFilterModal}
          >
            <Input
              name="Action name"
              value={oldActionFilterName}
              getValue={this.receiveValue}
              label={<T _str="Name of action filter" />}
              className={newActionErrors.length > 0 ? "error" : ""}
              focus
            />
            {newActionErrors.length > 0 &&
              newActionErrors.map((error, key) => (
                <div key={key} className="error">
                  - {error}
                </div>
              ))}
          </Dialog>
        )}

        {showLoadingScreen && <LoadingScreen />}

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {errorMessages.map((message, index) => {
            return <p key={index}>{message}</p>;
          })}
        </DialogError>
      </div>
    );
  }
}

const Title = ({ name }) => {
  const t = useT();
  return (
    <SceneTitle text={t("Actions")}>
      <p>{name}</p>
    </SceneTitle>
  );
};

export default Actions;
