import axios from "axios/index";

export type Color = {
  id: string;
  code: string;
  name: string;
  sort: number;
};

export default class ColorService {
  private static colorsCached?: Color[];
  private static fetchPromise?: Promise<Color[]>; // Track ongoing request (edge case)

  /**
   * Gets all colors available in the system.
   * The result is cached upon the first call and returned in subsequent usages.
   *
   * @returns {Promise<Color[]>}
   */
  public static async getColors(): Promise<Color[]> {
    if (this.colorsCached) {
      return Promise.resolve(this.colorsCached);
    }
  
    if (!this.fetchPromise) {
      this.fetchPromise = axios.get<Color[]>("/colors")
        .then(response => {
          this.colorsCached = response.data;
          return response.data;
        })
        .catch(error => {
          console.error("Failed to fetch colors:", error);
          throw error;
        })
        .finally(() => this.fetchPromise = undefined); // Ensure reset happens in all cases (success, error, abort)
    }
  
    return this.fetchPromise;
  }
}
